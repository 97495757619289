import ITEMS from './externalItemData';

export const AUTH_COOKIE = 'auth';
export const TEST_USER_COOKIE = 'testUser';
export const LOG_OBJECT_COOKIE = 'logObject';
export const PLAY_BG_MUSIC_ON_INIT_COOKIE = 'bgMusicOnInit';

export const eventOpts: AddEventListenerOptions & EventListenerOptions = { passive: false };

export const seasonOneEndDate = 1734998340;

export const MISSION_STATUS = {
  active: 'Active',
  inactive: 'Inactive',
} as const;

export const MISSION_TYPE = {
  Quest: 'Quest',
  EliteQuest: 'EliteQuest',
  GroupQuest: 'GroupQuest',
  Dungeon: 'Dungeon',
  Raid: 'Raid',
} as const;

export const MISSION_LABEL = {
  [MISSION_TYPE.Quest]: 'Quest',
  [MISSION_TYPE.EliteQuest]: 'Elite Quest',
  [MISSION_TYPE.GroupQuest]: 'Group Quest',
  [MISSION_TYPE.Dungeon]: 'Dungeon',
  [MISSION_TYPE.Raid]: 'Raid',
} as const;

export const BERA_NUMBER_REGEX = /Beramonium #(\d+)/g;

export const MISSION_CHALLENGES = {
  screechingHarpy: 'Screeching Harpy',
  terracottaTitans: 'Terracotta Titans',
  chainWarden: 'Chain Warden',
  thunderingBehemoth: 'Thundering Behemoth',
  arcaneTempest: 'Arcane Tempest',
  ironcladColossus: 'Ironclad Colossus',
  barricadeGuardian: 'Barricade Guardian',
  rockTitan: 'Rock Titan',
  infernoWielder: 'Inferno Wielder',
  frostLich: 'Frost Lich',
  whirlingReaver: 'Whirling Reaver',
  marauderChieftain: 'Marauder Chieftain',
  frenziedJuggernaut: 'Frenzied Juggernaut',
  plaguebringer: 'Plaguebringer',
  puppeteerSorcerer: 'Puppeteer Sorcerer',
  swarmingHiveQueen: 'Swarming Hive Queen',
  venomousStalker: 'Venomous Stalker',
  cursedWoundbringer: 'Cursed Woundbringer',
  arcaneBarrierGuardian: 'Arcane Barrier Guardian',
  enchantedArmorBehemoth: 'Enchanted Armor Behemoth',
  sandstormSentinel: 'Sandstorm Sentinel',
  arcaneBarrierWarden: 'Arcane Barrier Warden',
  shadowstalkerAssassin: 'Shadowstalker Assassin',
  labyrinthShadowbeast: 'Labyrinth Shadowbeast',
  deceptiveLabyrinthWeaver: 'Deceptive Labyrinth Weaver',
  skyborneTerror: 'Skyborne Terror',
  phantomLegionCommander: 'Phantom Legion Commander',
} as const;

export const MISSION_CHALLENGE_FALLBACK_BORDER_COLOR = '#a2b9b9';

export const LOOT_IMAGE_MAP = {
  'Small Lockbox': 'Loot_Pouch.png',
  'Bronze Casket': 'Bronze_Casket.png',
  'Golden Trove': 'Golden_Trove.png',
  'Grand Chest': 'Grand_Chest.png',
  'Enchanted Chest': 'Enchanted_Chest.png',
} as const;

export const MESSAGE_VALUES = {
  success: 'Success',
} as const;

export const MISSION_PURCHASE_PRICES = {
  Quest: [30, 35, 40, 45, 50, 55, 60, 65, 85, 95, 105, 115, 125],
  EliteQuest: [145, 165, 190, 210, 230, 250, 365, 430, 465],
  GroupQuest: [750, 1200, 1900, 2250],
  Dungeon: [3500, 6000, 7000],
  Raid: [28500, 35000, 40000],
} as const;

export const MISSION_REROLL_PRICES = {
  Quest: 2,
  EliteQuest: 10,
  GroupQuest: 65,
  Dungeon: 300,
  Raid: 1000,
};

export const ITEM_TYPES = {
  NFT: 'NFT',
  Collectible: 'Collectable',
  Equipment: 'Equipment',
  Crafting: 'Crafting',
} as const;

export const SEARCH_PARAMS = {
  filter: 'filter',
  sortBy: 'sortBy',
};

export const GEAR_RARITY_TYPES = {
  Common: 'Common',
  Rare: 'Rare',
  Epic: 'Epic',
  Legendary: 'Legendary',
};

export const GEAR_RARITY = {
  [GEAR_RARITY_TYPES.Common]: 1,
  [GEAR_RARITY_TYPES.Rare]: 3,
  [GEAR_RARITY_TYPES.Epic]: 5,
  [GEAR_RARITY_TYPES.Legendary]: 8,
} as const;

export const BERA_SPECS = {
  tank: 'Tank',
  berserker: 'Berserker',
  spellcaster: 'Spellcaster',
  rogue: 'Rogue',
  healer: 'Healer',
  marksman: 'Marksman',
  unique: 'Unique',
} as const;

export const WEAPON_TYPES = {
  Book: 'Book',
  Staff: 'Staff',
  '2HSword': '2HSword',
  'Sword&Shield': 'Sword&Shield',
  Bow: 'Bow',
  Dagger: 'Dagger',
};

export const WEAPON_SPECS_MAP = {
  [WEAPON_TYPES['Sword&Shield']]: BERA_SPECS.tank,
  [WEAPON_TYPES['2HSword']]: BERA_SPECS.berserker,
  [WEAPON_TYPES.Staff]: BERA_SPECS.healer,
  [WEAPON_TYPES.Book]: BERA_SPECS.spellcaster,
  [WEAPON_TYPES.Bow]: BERA_SPECS.marksman,
  [WEAPON_TYPES.Dagger]: BERA_SPECS.rogue,
};

export const NFT_COLLECTIONS_SEASON_1 = [
  'Yeet',
  'Bera Punk',
];

export type UniqueSpec = 'Unique';

export type RegularSpec = (typeof BERA_SPECS)[keyof typeof BERA_SPECS];

export const BERA_SPEC_COLOR_MAP = {
  [BERA_SPECS.tank]: '#c28436',
  [BERA_SPECS.berserker]: '#c40b2a',
  [BERA_SPECS.spellcaster]: '#56cae8',
  [BERA_SPECS.rogue]: '#d9cc2c',
  [BERA_SPECS.healer]: '#3b3bc9',
  [BERA_SPECS.marksman]: '#188a76',
  [BERA_SPECS.unique]: '#be69e5',
};

export const BERA_CLASS = {
  warrior: 'Warrior',
  mage: 'Mage',
  hunter: 'Hunter',
} as const;

export const BERA_CLASS_ICON_MAP = {
  [BERA_CLASS.mage]: '/pngIcons/chaos.png',
  [BERA_CLASS.warrior]: '/pngIcons/hammer.png',
  [BERA_CLASS.hunter]: '/pngIcons/bow.png',
};

export const BERA_UNIQUE_CLASS_ICON = '/pngIcons/boss.png';

export const ITEM_RARITY_COLOR_MAP = {
  Common: '#76E841',
  Rare: '#2E61E2',
  Epic: '#603aea',
  Legendary: '#FF5C00',
  Unique: '#be69e5',
};

export const BERA_ATTRIBUTES = {
  Collection: 'Collection',
  Tier: 'Tier',
  Class: 'Class',
  Background: 'Background',
  Aura: 'Aura',
  Body: 'Body',
  Expression: 'Expression',
  Pants: 'Pants',
  Boots: 'Boots',
  Gauntlets: 'Gauntlets',
  Chest: 'Chest',
  Helmet: 'Helmet',
  Weapon: 'Weapon',
  GearScore: 'Gear Score',
  Spec: 'Spec',
} as const;

export const EQUIPMENT_ATTRIBUTES = {
  [BERA_ATTRIBUTES.Helmet]: BERA_ATTRIBUTES.Helmet,
  [BERA_ATTRIBUTES.Pants]: BERA_ATTRIBUTES.Pants,
  [BERA_ATTRIBUTES.Chest]: BERA_ATTRIBUTES.Chest,
  [BERA_ATTRIBUTES.Gauntlets]: BERA_ATTRIBUTES.Gauntlets,
  [BERA_ATTRIBUTES.Boots]: BERA_ATTRIBUTES.Boots,
  [BERA_ATTRIBUTES.Weapon]: BERA_ATTRIBUTES.Weapon,
} as const;

export const DUST_TYPE = {
  'Green Dust': 'Green Dust',
  'Blue Dust': 'Blue Dust',
  'Purple Dust': 'Purple Dust',
  'Orange Dust': 'Orange Dust',
} as const;

export const BERA_TIER = {
  genesis: 'genesis',
  evolved: 'evolved',
  naked: 'naked',
} as const;

export const SORT_DIRECTION = {
  ascending: 'ascending',
  descending: 'descending',
} as const;

// The unique (1/1) beras use their names as the item names
const UNIQUE_ITEMS = {
  Hologram: 'Unique',
  'Zelemor the Golden Necromancer': 'Unique',
  'The Dark Knight': 'Unique',
  'Ikarus the Golden Paladin': 'Unique',
  'Olgrem of Thunders': 'Unique',
  'Kishant the Golden Druid': 'Unique',
  'Waiku the Golden Fighter': 'Unique',
  'The Void Mage': 'Unique',
  'Nerol the Golden Sorcerer': 'Unique',
  'Ulhal the Golden Rogue': 'Unique',
  'The Holy Dark Spirit': 'Unique',
  'The Holy Light Spirit': 'Unique',
  'Killi Rio the Golden Archer': 'Unique',
} as const;

export const ITEM_RARITY = {
  ...Object.fromEntries(Object.values(ITEMS).map(({ Name, Rarity }) => [Name, Rarity] as const)),
  ...UNIQUE_ITEMS as Record<string, 'Unique'>,
} as const;
