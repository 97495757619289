import React, { useEffect } from 'react';
import ScaleFadeInWrapper from '../../ScaleFadeInWrapper';
import GenericMainScreenContainer from '../../GenericMainScreenContainer/GenericMainScreenContainer';
import OverflowWithMaskWrapper from '../../OverflowWithMaskWrapper';
import { useAppSelector } from '../../../store/hooks';
import modalService from '../../../modalService/ModalService';
import ErrorModal from '../../modals/ErrorModal';
import LeaderboardEntry from './LeaderboardEntry';
import LeaderboardOverNthEntry from './LeaderboardOverNthEntry';
import LeaderboardSeasonPrizes from './LeaderboardSeasonPrizes';
import useTailwindBreakpoint from '../../../utils/useTailwindBreakpoint';
import MissionTimer from '../MissionBoard/MissionTimer';
import { seasonOneEndDate } from '../../../utils/consts';
import GenericMainScreenHeader from '../../GenericMainScreenContainer/GenericMainScreenHeader';

const gridStyle = {
  display: 'grid',
  gridTemplateColumns: '0.3fr 0.7fr 1fr',
};

const LeaderboardLeftColumn = ({
  extraClassname,
}: {
    extraClassname?: string
}) => (
  <div
    className={`
        relative basis-2/5 flex-grow flex flex-col justify-evenly
        ${extraClassname}
    `}
    style={{
      backgroundColor: 'rgb(48,42,73)',
    }}
  >
    <div
      className="absolute left-0 top-0 right-0 bottom-0 opacity-20"
      style={{
        backgroundImage: 'url(\'/map_1.png\')',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    />
    <div
      className="min-w-[16rem] h-[4rem]"
      style={{
        backgroundImage: 'url(\'/logo.png\')',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    />
    <div className="font-title text-white text-5xl text-center">
      <div className="drop-shadow-inheritColorExtraSmall">TESTNET SEASON</div>
      <div className="font-title text-white text-2xl text-center">The bArtio Boogaloo 🐻⛓</div>
    </div>
    <LeaderboardSeasonPrizes extraClassname="mb-4 sm:mb-8" />
    <div className="flex flex-row justify-center text-2xl sm:!text-3xl text-white gap-2">
      <MissionTimer
        missionTimer={{
          start: { _seconds: 1710000118 },
          finish: { _seconds: seasonOneEndDate },
        }}
      />
    </div>
  </div>
);

export default () => {
  const isFetchingData = useAppSelector((state) => state.leaderboard.isFetchingData) as boolean;
  const leaderboardEntries = useAppSelector((state) => state.leaderboard.leaderboardEntries);
  const totalPlayerCount = useAppSelector((state) => state.leaderboard.totalPlayerCount);
  const currentUserEntry = useAppSelector((state) => state.leaderboard.currentUserEntry);

  useEffect(() => {
    if (!leaderboardEntries) {
      modalService.pushModal(<ErrorModal error={new Error('empty leaderboard!!')} />); // TODO better error handling
    }
  }, [leaderboardEntries]);

  const breakpoint = useTailwindBreakpoint();

  const shouldHidePrizePool = breakpoint && ['sm', 'md', 'lg'].includes(breakpoint);

  if (!leaderboardEntries || !currentUserEntry) {
    return null;
  }

  const leaderboardEntryCount = leaderboardEntries.length;

  return (
    <ScaleFadeInWrapper>
      <GenericMainScreenContainer
        headerElement={ <GenericMainScreenHeader
          tabs={[{
            label: 'LEADERBOARD',
          }]}
          goBackTo={shouldHidePrizePool && '/leaderboard'}
          goBackToLabel={'Prize pool'}
          goBackToOnClick={() => modalService.pushModal(
            <LeaderboardLeftColumn extraClassname="py-4 rounded m-[-30px] overflow-hidden"/>,
            {
              shouldDisplayCloseButton: true,
            },
          )}
        />}
        extraClassName="!flex-row"
      >
        <LeaderboardLeftColumn extraClassname="hidden py-8 lg:flex" />
        <div className={`
            basis-3/5 flex-grow flex flex-col overflow-hidden
            ${isFetchingData && 'pointer-events-none opacity-50'}
        `}
        >
          <div
            className="w-full text-center text-xl text-white my-6"
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gridRowGap: '2px',
            }}
          >
            <div>Your rank:</div>
            <div>Total players:</div>
            <div className="text-3xl font-bold">{currentUserEntry.rank}</div>
            <div className="text-3xl font-bold">{totalPlayerCount}</div>
          </div>
          <div
            className="my-2"
            style={gridStyle}
          >
            <div className="text-left pl-4 text-white font-bold">Rank</div>
            <div className="text-center text-white font-bold">Wallet / ENS</div>
            <div className="text-right pr-4 text-white font-bold">Total Gems earned</div>
          </div>
          <OverflowWithMaskWrapper gradientCutoffEnabled>
            <div style={gridStyle}>
              {leaderboardEntries.map((leaderboardEntry, index) => (
                <LeaderboardEntry
                  key={`${index}${leaderboardEntry.walletAddress}`}
                  index={index}
                  leaderboardEntry={leaderboardEntry}
                />
              ))}
              <LeaderboardOverNthEntry
                overNthCutoffValue={leaderboardEntryCount}
                currentUserEntry={currentUserEntry}
              />
            </div>
          </OverflowWithMaskWrapper>
        </div>
      </GenericMainScreenContainer>
    </ScaleFadeInWrapper>
  );
};
