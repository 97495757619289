import { useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from '../consts';
import getSearchParamsValue from './definitions/getSearchParamsValue';

export default function <T extends Array<Record<string, any>>> (arrayToSort: T, sortBy: Record<string, string>): T {
  const [searchParams] = useSearchParams();

  Object.entries(sortBy).forEach(([searchParamKey, sortByKey]) => {
    const currentSearchParamValue = getSearchParamsValue(SEARCH_PARAMS.sortBy, searchParams, searchParamKey);

    arrayToSort.sort((record, newRecord) => {
      const recordValue = sortByKey.split('.').reduce((acc, val) => (acc ? acc[val] : record[val]), undefined);
      const newRecordValue = sortByKey.split('.').reduce((acc, val) => (acc ? acc[val] : newRecord[val]), undefined);

      if (typeof recordValue !== 'number') {
        // eslint-disable-next-line no-console
        console.warn(recordValue, ' - only sort by numeric value supported!');

        return 0;
      }

      if (typeof newRecordValue !== 'number') {
        // eslint-disable-next-line no-console
        console.warn(newRecordValue, ' - only sort by numeric value supported!');

        return 0;
      }

      if (currentSearchParamValue === 'ascending') {
        return recordValue - newRecordValue;
      }

      if (currentSearchParamValue === 'descending') {
        return newRecordValue - recordValue;
      }

      return 0;
    });
  });

  return arrayToSort;
}
