import { useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from '../consts';

export default function <T extends Array<Record<string, any>>> (arrayToFilter: T, filterBy: Record<string, string | {
  mapTo: string,
  // eslint-disable-next-line no-unused-vars
  shouldDisplayCondition?: (filterValue: string, elementValue: string) => boolean
}>): T {
  const [searchParams] = useSearchParams();
  const filterSearchParamsValue = searchParams.get(SEARCH_PARAMS.filter);

  if (!filterSearchParamsValue) {
    return arrayToFilter as T;
  }

  const currentFilterValues = filterSearchParamsValue?.split(',').reduce((acc, val) => {
    const [key, value] = val.split('.');

    return {
      [key]: value,
      ...acc,
    };
  }, {}) as Record<string, string>;

  return arrayToFilter.filter((element) => {
    let shouldDisplay = true;

    Object.entries(filterBy).forEach(([filterKey, elementKey]) => {
      const filterValue = currentFilterValues[filterKey];

      if (filterValue) {
        if (typeof elementKey === 'string') {
          let elementValue = elementKey.split('.').reduce((acc, val) => (acc ? acc[val] : element[val]), undefined) as any;

          if (typeof elementValue === 'number') {
            elementValue = elementValue.toString();
          }

          if (typeof elementValue !== 'string') {
            // console.warn(elementKey, ' - only filter by string value supported!');
          } else if (filterValue.toLowerCase() !== (elementValue as string).toLowerCase()) {
            shouldDisplay = false;
          }
        } else {
          const { mapTo, shouldDisplayCondition } = elementKey;
          let elementValue = mapTo.split('.').reduce((acc, val) => (acc ? acc[val] : element[val]), undefined) as any;

          if (typeof elementValue === 'number') {
            elementValue = elementValue.toString();
          }

          if (typeof elementValue !== 'string') {
            // console.warn(elementKey, ' - only filter by string value supported!');
          } else if (shouldDisplayCondition) {
            shouldDisplay = shouldDisplayCondition(filterValue, elementValue);
          } else if (filterValue.toLowerCase() !== (elementValue as string).toLowerCase()) {
            shouldDisplay = false;
          }
        }
      }
    });

    return shouldDisplay;
  }) as T;
}
